import type { LoaderFunctionArgs } from "@remix-run/node"

export async function loader({ request }: LoaderFunctionArgs) {
	return {}
}

export default function Index() {
	return (
		<section className="mx-auto flex h-screen w-screen max-w-7xl flex-col items-center justify-center gap-8 text-3xl">
			<div>
				SCHOOL HUBはサービスを終了しました。ご利用ありがとうございました。
			</div>
		</section>
	)
}
